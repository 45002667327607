@use "../Modules" as *;

// Clouds
@keyframes wind{
  0% {
    background-position: -150px 52px, 0 8%;
  }
  100% {
    background-position: 50% 52px, 500px 8%;
  }
}

// Up and Down
@keyframes upAndDown{
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}

// Right and Left
@keyframes rightAndLeft{
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(10px);
  }
  100% {
    transform: translateX(0);
  }
}

// Conffeti 
@keyframes conffeti{
  0% {
    transform: translateY(-90%);
  }
  100% {
    transform: translateY(100vh);
    opacity: 0;
  }
}

@keyframes click{
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}

// Up and Down Ballon
@keyframes upAndDownBallon{
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-30px);
  }
  100% {
    transform: translateY(0);
  }
}

// Cloud Bottom 
@keyframes cloudBottom{
  0% {
    background-position: -350px bottom;
  }
  100% {
    background-position: 350% bottom;
  }
}

@keyframes ballonBottomPart{
  0% {
    position: relative;
    transform: rotate(5deg);
    scale: .9;
    top: 50px;
  }

  100% {
    transform: inherit;
    bottom: inherit;
    position: relative;
    scale: inherit;
  }
}

@keyframes ballonTopPart{
 0% {
    transform: rotate(338deg);
    bottom: 75px;
    position: relative;
    scale: 1.18;
  }

  100% {
    transform: inherit;
    bottom: inherit;
    position: relative;
    scale: inherit;
  }
}