.loading {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.7);
  display: block;
  height: 100%;

  .ico {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 150px;
  }
}