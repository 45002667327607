@use '../../Resources/Sass/Modules/' as *;

button {
  display: flex;
  justify-content: center;
  align-items: center;

  &:disabled {
    cursor: no-drop;
  }
}

.contained {
  background-color: var(--color-orange) !important;
  height: 40px;
  border-radius: 8px;
  color: var(--color-white);
  font-size: 20px;
  font-weight: var(--font-semibold);
}
