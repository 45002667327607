:root {
  --color-black-900: #000000;
  --color-white: #ffffff;

  --color-red-error: #cc0000;

  --color-purple: #2d294b;
  --color-orange: #fc5f3e;
  --color-blue-light: #E4F4F3;

  --font-lighter: 200;
  --font-light: 300;
  --font-regular: 400;
  --font-medium: 500;
  --font-semibold: 600;
  --font-bold: 700;

  --font-oswald: 'Oswald', sans-serif;
}
