@use '../Modules' as *;

table{

  tr{
    td{
      padding: 0 10px;
    }
  }

}