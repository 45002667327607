label {
  color: var(--color-purple);
  font-size: 14px;
}

.input-field {
  
  height: 39px !important;

  input {
    padding: 3px 14px;
    color: #8d79b4;
    font-weight: var(--font-light);
    font-size: 22px;
    text-align: center;
    background-color: var(--color-white);
    border: 1px solid #8d79b4;
  }
}
