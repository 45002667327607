@use '../../../Sass/Modules/' as *;

$conffeti : url('../../../Media/Site/conffets.webp') no-repeat center center;

.prize{
  display: block;
  background: $conffeti;
  width: 100%;
  background-size: 500px;
  overflow: hidden;

  &:before,
  &::after{
    content: '';
    position: absolute;
    opacity: 1;
    z-index: 1;
    display: block;
  }


  figure[role="ShowWhereToClick"],
  figure[role="BallonToExplode"]{
    position: relative;
    display: block;
    cursor: pointer;
    z-index: 2;
  }

  figure[role="ShowWhereToClick"]{
    animation: click 1s infinite;
    z-index: 3;
  }

  figure[role="BallonToExplode"]{
    animation: upAndDownBallon 10s infinite;
  }

  .prize-win-box{
    position: relative;
    top: -285px;
    left: auto;
    right: auto;
    width: 290px;
    height: 50px;
    opacity: 1;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--color-orange); 
    border-radius: 50px;
    margin: auto;  
    color: var(--color-white);
    font-size: 18px;
  }

  .text-win > strong {
    color: #F9A51A;
  }

  .big-text > p {
    line-height: 1.4em;
    margin-top: 5px;
  }
}
