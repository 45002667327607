@use '../Modules' as *;

form {
  * {
    appearance: none !important;
  }

  :focus {
    outline: none !important;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    -webkit-text-fill-color: var(--color-blue) !important;
  }

  input {
    border-radius: 24px;
  }
}

input,
select,
textarea,
label {
  font-family: var(--font-myriad);
}

input,
select,
textarea {
  width: 100%;
  @include border-radius(12px);
  padding: 10px 20px;
  line-height: 1;
  background-color: transparent;
  transition: all 0.3s ease;
  color: var(--color-blue-100);
  font-weight: var(--font-semibold);
  @include font-size(14px);
  font-style: normal;

  &::placeholder {
    @include font-size(14px);
    color: var(--color-blue-100);
    font-weight: var(--font-semibold);
    width: 100%;
    font-style: normal;
  }

  &::placeholder {
    padding-right: 0;
    font-style: normal;
  }
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  background-color: var(--color-pink);
}
