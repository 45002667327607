@use "../Modules" as *;

// $right: "../../../../Resources/Media/Site/Icons/right.svg";
// $left: "../../../../Resources/Media/Site/Icons/left.svg";

.slick-dots {
  bottom: -20px;

  li {
    width: 28px;
    height: 28px;
    background: var(--color-white);
    border-radius: 50%;
    button {
      &::before {
        color: var(--color-white);
      }
    }
  }
  li.slick-active {
    button {
      &::before {
        font-size: 20px;
        line-height: 30px;
        width: 28px;
        height: 28px;
        opacity: 1;
        color: var(--color-blue);
      }
    }
  }
}

.slick-next,
.slick-prev {
  z-index: 9;
  top: 50%;
  &::before {
    opacity: 1;
  }
}

.slick-next,
.slick-prev {
  &::before {
    content: "";
    position: absolute;
    display: block;
    z-index: 0;
    width: 35px;
    height: 35px;
    bottom: -10px;
  }
}

.slick-next {
  &::before {
    // background: url("#{$right}") no-repeat bottom;
    background-size: cover;
    right: 50px;
  }
}
.slick-prev {
  &::before {
    // background: url("#{$left}") no-repeat bottom;
    background-size: cover;
    left: 50px;
  }
}

@include responsive(atMedium) {
  .slick-dots {
    bottom: -35px;
  }
  .slick-next {
    &::before {
      right: 10px;
    }
  }
  .slick-prev {
    &::before {
      left: 10px;
    }
  }
}
