@use '../Resources/Sass/Modules/' as *;
@use '../Resources/Sass/Components/' as *;

@tailwind base;
@tailwind components;
@tailwind utilities;

@import '../Styles/variables.scss';

$cloudBottom : url('../Resources/Media/Site/cloud-bottom.webp') no-repeat center center;


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: 0;
}

html,
body,
#root {
  min-height: 100vh;
  width: 100%;
  background-color: var(--color-blue-light);
}

#root-container {
  overflow: hidden;
}

body {
  height: 100vh;
  font-family: var(--font-oswald);

  &.menu-open {
    overflow: hidden;
  }

  overflow-x: hidden !important;
  overflow-y: auto !important;
}

.container {
  max-width: 1088px;
}
.container-fluid {
  margin-left: 1rem;
  margin-right: 1rem;
}

main{
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;

  &::after{
    content: '';
    position: absolute;
    opacity: 1;
    z-index: 1;
    display: blocak;
  }

  &::after {
    content: '';
    width: 100%;
    height: 100%;
    background: $cloudBottom;
    background-size: 60%;
    animation: cloudBottom 15s linear infinite;
    bottom: 0px;
  }


}

.error {
  color: var(--color-red-error);
  font-style: normal;
  font-size: 14px !important;

  label {
    color: var(--color-red-error);
  }

  input {
    color: var(--color-red-error);
    &::placeholder {
      color: var(--color-red-error);
    }
  }
}
