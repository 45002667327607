@use '../Modules' as *;

.errors-message {
  margin: 5px 0px 5px 20px;
  display: flex;
  align-items: baseline;
  img {
    margin-right: 5px;
    width: 20px;
    height: 15px;
  }
}


.error {
  color: var(--color-red-error);
  position: relative;
  font-size: 12px !important;

  label {
    color: var(--color-red-error);
  }

  input {
    color: var(--color-red-error);
    &::placeholder {
      color: var(--color-red-error);
    }
  }

  textarea {
    border: 1px solid var(--color-red-error) !important;
    &::placeholder {
      color: var(--color-red-error);
    }
  }

  .input-field {
    border: 1px solid var(--color-red-error) !important;
  }

  select {
    color: var(--color-red-error) !important;
  }
}