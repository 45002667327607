@use "../Modules" as *;

.title {
  h1,
  p {
    color: var(--color-blue);
  }

  h1 {
    @include font-size(22px);

    strong {
      color: var(--color-pink);
    }
  }

  p {
    @include font-size(14px);
  }

  @include responsive(atMedium) {
    p {
      @include font-size(16px);
    }
  }
}
