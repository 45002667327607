@mixin responsive($breakpoints) {
  @if $breakpoints == atUltraSmall {
    @media only screen and (min-width: 360px) {
      @content;
    }
  }

  @if $breakpoints == atMediumSmall {
    @media only screen and (min-width: 375px) {
      @content;
    }
  }

  @if $breakpoints == atSmall {
    @media only screen and (min-width: 390px) {
      @content;
    }
  }

  @if $breakpoints == atLargeSmall {
    @media only screen and (min-width: 420px) {
      @content;
    }
  }

  @if $breakpoints == atMedium {
    @media only screen and (min-width: 1024px) {
      @content;
    }
  }

  @if $breakpoints == atLarge {
    @media only screen and (min-width: 1366px) {
      @content;
    }
  }

  @if $breakpoints == tabletLandscape {
    @media only screen and (device-width: 1024px) and (device-height: 600px) {
      @content;
    }
  }
}

@mixin border-radius($radius) {
  border-radius: $radius;
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
}

@mixin container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}

@mixin background-radius(
  $color-1: var(--color-white-100),
  $color-2: var(--color-gray-background-100),
  $color-3: var(--color-gray-background-400),
  $color-4: var(--color-gray-background-600),
  $color-5: var(--color-gray-background-700),
  $color-6: var(--color-gray-background-800)
) {
  background-image: radial-gradient(
    ellipse at center,
    $color-1 16%,
    $color-2 36%,
    $color-3 51%,
    $color-4 76%,
    $color-5 87%,
    $color-6 92%
  );

  @include responsive(atMedium) {
    background-image: radial-gradient(
      ellipse at center,
      $color-1 46%,
      $color-2 53%,
      $color-3 67%,
      $color-4 76%,
      $color-5 87%,
      $color-6 92%
    );
  }
}
