@use '../../../Sass/Modules/' as *;

$clouds: url('../../../Media/Site/clouds.webp') no-repeat;
$cloudBottom : url('../../../Media/Site/cloud-bottom.webp') no-repeat center center;
$personas-left : url('../../../Media/Site/personas-left.webp') no-repeat;
$personas-right : url('../../../Media/Site/personas-right.webp') no-repeat;
$conffeti : url('../../../Media/Site/conffets.webp') no-repeat center center;

.home{
  display: block;
  position: relative;
  z-index: 10;
  width: 100%;
  background: $conffeti;
  background-size: 500px;

  &-clouds{
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    animation: wind 50s linear ;
    background: $clouds 50% 52px, $clouds 1500px 60px;
    background-size: 218px 204px, 218px 204px;
    z-index: 1;
    display: block;
  }


  &-wrap{

    &-title{
      position: relative;
      display: block; 
      width: max-content;
      margin: 0 auto;

      &::before,
      &::after{
        content: '';
        position: absolute;
        z-index: 1;
      }

      &::before{
        top: -70px;
        left: -130px;
        width: 232px;
        height: 239px;
        background: $personas-left;
        background-size: contain;
        animation: upAndDown 5s linear infinite;
        
      }

      &::after{
        top: -28px;
        right: -135px;
        width: 175px;
        height: 322px;
        background: $personas-right;
        background-size: contain;
        animation: rightAndLeft 5s linear infinite;
      }


      .teen,
      .years,
      .sonora{
        display: block;
        margin: 0 auto 10px auto;
      }

      .teen{
        width: 121px;
      }
      .years{
        width: 129px;
      }
      .sonora{
        width: 135px;
      }
    }
  }
}